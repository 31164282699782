import {
  deleteAffiliateRebateRuleProductOverride,
  getAffiliateBudgetDataAPI,
  getAllAffiliateRebateRuleProductOverrides,
  patchAffiliateBudgetRecalculateAPI,
  postAffiliateBudgetAPI,
} from '@/Axios/api/affiliate';
import { AffiliateBudgetParam, AffiliateBudgetPostData } from '@/Interfaces/I_Affiliate';
import { PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { RebateRuleOverride } from '@/Interfaces/I_RebateOverride';
import {
  getAffiliateBudgetData,
  getAllRebateRuleProductOverrides,
  patchAffiliateBudgetRecalculate,
  patchAffiliateBudgetRecalculateSuccess,
  postAffiliateBudget,
  postAffiliateKocBudgetSuccess,
  postAffiliateVodBudgetSuccess,
  setAffiliateKocBudgetData,
  setAffiliateVodBudgetData,
  setRebateRuleProductOverrides,
  deleteRebateRuleProductOverride,
  deleteRebateRuleProductOverrideSuccess,
} from '../slices/AffiliateSlice';

function* handleGetAffiliateBudgetData(action: PayloadAction<AffiliateBudgetParam>) {
  try {
    const data: AxiosResponse = yield call(getAffiliateBudgetDataAPI, action.payload);
    if (action.payload.post_type === 'NORMAL') {
      yield put(setAffiliateKocBudgetData(data));
    } else {
      yield put(setAffiliateVodBudgetData(data));
    }
  } catch (e) {
    message.error({
      content: 'Fail to get budget policy list.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

function* handlePostAffiliateBudget(action: PayloadAction<AffiliateBudgetPostData>) {
  try {
    const data: AxiosResponse = yield call(postAffiliateBudgetAPI, action.payload);
    if (data.data.status === 100) {
      if (action.payload.postType === 'NORMAL') {
        yield put(postAffiliateKocBudgetSuccess(data.data.data));
      } else {
        yield put(postAffiliateVodBudgetSuccess(data.data.data));
      }
      message.success({
        content: 'Affiliate new budget is created.',
        duration: 3,
        style: {
          marginTop: '120px',
        },
      });
    }
  } catch (e) {
    message.error({
      content: 'Fail to create affiliate new budget.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

function* handlePatchAffiliateBudgetRecalculate(action: PayloadAction<number>) {
  try {
    const data: AxiosResponse = yield call(patchAffiliateBudgetRecalculateAPI, action.payload);
    if (data.data.status === 100) {
      yield put(patchAffiliateBudgetRecalculateSuccess(data.data.data));
      message.success({
        content: 'Affiliate budget spent amount is recalculated.',
        duration: 3,
        style: {
          marginTop: '120px',
        },
      });
    }
  } catch (e) {
    message.error({
      content: 'Fail to recalculate affiliate budget spent amount.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

function* handleGetAllRebateRuleProductOverrides() {
  try {
    const data: AxiosResponse = yield call(getAllAffiliateRebateRuleProductOverrides);
    yield put(setRebateRuleProductOverrides(data.data));
  } catch (e) {
    message.error({
      content: 'Fail to fetch affiliate rebate rule product overrides.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

function* handleDeleteRebateRuleProductOverride(action: PayloadAction<RebateRuleOverride>) {
  try {
    yield call(deleteAffiliateRebateRuleProductOverride, action.payload);
    yield put(deleteRebateRuleProductOverrideSuccess(action.payload));
  } catch (e) {
    message.error({
      content: 'Fail to delete rebate rule product override.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

export function* watchGetAffiliateBudgetData() {
  yield takeLatest(getAffiliateBudgetData.type, handleGetAffiliateBudgetData);
}

export function* watchPostAffiliateBudget() {
  yield takeEvery(postAffiliateBudget.type, handlePostAffiliateBudget);
}

export function* watchPatchAffiliateBudgetRecalculate() {
  yield takeEvery(patchAffiliateBudgetRecalculate.type, handlePatchAffiliateBudgetRecalculate);
}

export function* watchGetAllRebateRuleProductOverrides() {
  yield takeLatest(getAllRebateRuleProductOverrides.type, handleGetAllRebateRuleProductOverrides);
}

export function* watchDeleteRebateRuleProductOverride() {
  yield takeEvery(deleteRebateRuleProductOverride.type, handleDeleteRebateRuleProductOverride);
}
