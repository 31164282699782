/* eslint-disable @typescript-eslint/no-unused-vars */

import { Page } from '@/Interfaces/common';
import { RebateOverride } from '@/Interfaces/I_RebateOverride';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  rebateOverrides: Page<RebateOverride>;
  uploadedRebateOverride: RebateOverride;
  loading: boolean;
  errorMessage: string;
}

const initialState: InitialState = {
  rebateOverrides: {
    totalPages: 1,
    currentPage: 1,
    totalElements: 0,
    data: [] as RebateOverride[],
  },
  uploadedRebateOverride: {} as RebateOverride,
  loading: false,
  errorMessage: '',
};

const rebateOverrideSlice = createSlice({
  name: 'rebateOverrideSlice',
  initialState,
  reducers: {
    getRebateOverrides: (state, _action: PayloadAction<{ page: number }>) => {
      const assignState = state;
      assignState.loading = true;
    },
    setRebateOverrides: (state, action: PayloadAction<Page<RebateOverride>>) => {
      const assignState = state;
      assignState.loading = false;
      assignState.rebateOverrides = action.payload;
    },
    createRebateOverride: (state, _action: PayloadAction<RebateOverride>) => {
      const assignState = state;
      assignState.loading = true;
    },
    createRebateOverrideSuccess: (state, action: PayloadAction<RebateOverride>) => {
      const assignState = state;
      const { totalElements, data } = assignState.rebateOverrides;
      assignState.loading = false;
      assignState.rebateOverrides = {
        ...assignState.rebateOverrides,
        totalPages: Math.ceil((totalElements + 1) / 10),
        totalElements: totalElements + 1,
        data: [...data, action.payload],
      };
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      const assignState = state;
      assignState.loading = false;
      assignState.errorMessage = action.payload;
    },
  },
});

export const {
  getRebateOverrides,
  setRebateOverrides,
  createRebateOverride,
  createRebateOverrideSuccess,
  setErrorMessage,
} = rebateOverrideSlice.actions;

export default rebateOverrideSlice.reducer;
