import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getAllRebateOverrides,
  createRebateOverride as createRebateOverrideApi,
} from '@/Axios/api/rebateOverride';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RebateOverride } from '@/Interfaces/I_RebateOverride';
import { message } from 'antd';
import { Page } from '@/Interfaces/common';
import {
  createRebateOverrideSuccess,
  createRebateOverride,
  getRebateOverrides,
  setErrorMessage,
  setRebateOverrides,
} from '../slices/RebateOverrideSlice';

function* handleGetRebateOverrides(action: PayloadAction<{ page: number }>) {
  try {
    const { page } = action.payload;
    const data: AxiosResponse<Page<RebateOverride>> = yield call(getAllRebateOverrides, page);
    yield put(setRebateOverrides({ ...data.data, totalElements: 50 }));
  } catch (e) {
    const errorMessage = (e as AxiosError).response?.data.message;
    yield put(setErrorMessage(errorMessage));
    message.error({
      content: 'Fail to fetch rebate overrides.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

function* handleCreateRebateOverride(action: PayloadAction<RebateOverride>) {
  try {
    const data: AxiosResponse<RebateOverride> = yield call(createRebateOverrideApi, action.payload);
    yield put(createRebateOverrideSuccess(data.data));
  } catch (e) {
    const errorMessage = (e as AxiosError).response?.data.message;
    yield put(setErrorMessage(errorMessage));
    message.error({
      content: `Fail to create rebate override: ${errorMessage}.`,
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

export function* watchGetRebateOverrides() {
  yield takeLatest(getRebateOverrides.type, handleGetRebateOverrides);
}

export function* watchCreateRebateOverride() {
  yield takeLatest(createRebateOverride.type, handleCreateRebateOverride);
}
