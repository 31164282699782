/* eslint-disable react/jsx-props-no-spreading */
import { RebateOverride, RebateRuleOverride } from '@/Interfaces/I_RebateOverride';
import { Button, DatePicker, Form, Input, message, Modal, ModalProps, Table, Upload } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';

type RebateModalProps = {
  data?: RebateOverride;
  onSubmit: (rebateOverride: RebateOverride) => void;
  setModalOpen: (value: boolean) => void;
} & Omit<ModalProps, 'onOk'>;

const nearest30Minute = () => {
  const current = moment();
  const minutes = current.minute();
  const roundedMinutes = Math.round(minutes / 30) * 30;
  return current.minute(roundedMinutes).second(0);
};

const csvHeaders = [
  'parent_sku_code',
  'post_type',
  'author_koc_user_role',
  'author_rebate_rate',
  'buyer_koc_user_role',
  'buyer_rebate_rate',
];

const DownloadLink = () => (
  <CSVLink
    uFEFF
    data={`
### post_type: NORMAL, VOD
### koc_user_role: MERCHANT, OFFICIAL, CONTENT_PROVIDER, CUSTOMER, HKTV_SHOPS_MERCHANT, YOUTUBER
### PLEASE DO NOT INCLUDE THESE 3 LINES IN THE UPLOADED CSV
    `}
    style={{ color: 'rgba(0, 0, 0, 0.85)' }}
    target="_blank"
    filename="rebate_override_template.csv"
    headers={csvHeaders}
  >
    <Button>Download Template</Button>
  </CSVLink>
);

const DateTimePicker = ({
  minDate,
  onChange,
  valid,
}: {
  minDate?: Moment;
  onChange: (date: Moment | null, dateString: string) => void;
  valid: boolean;
}) => (
  <DatePicker
    showTime={{
      defaultValue: nearest30Minute(),
      minuteStep: 30,
    }}
    format="YYYY-MM-DD HH:mm:ss"
    disabledDate={(date) => (minDate ? date < minDate : false)}
    onChange={(m, dateString) => onChange(m, dateString)}
    showNow={false}
    showSecond={false}
    status={valid ? '' : 'error'}
  />
);

const CreateRebateOverrideModal = ({ onSubmit, setModalOpen, ...modalProps }: RebateModalProps) => {
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [rebateRules, setRebateRules] = useState<RebateRuleOverride[]>([]);
  const [uploading, setUploading] = useState(false);

  const onUploadClick = useCallback((csv: Blob) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e.target?.result as string;
      const lines = content.split('\n');

      const matchedHeadersOrder = lines[0]
        .replaceAll('\r', '')
        .split(',')
        .every(
          (header, index) =>
            index >= csvHeaders.length || header.replaceAll('"', '') === csvHeaders[index],
        );

      if (!matchedHeadersOrder) {
        message.error({
          content: 'csv headers are not valid',
          duration: 3,
          style: {
            marginTop: '120px',
          },
        });
        return;
      }

      const result: RebateRuleOverride[] = lines
        .slice(1)
        .filter((line) => line.length > 0)
        .map((line) => {
          const values = line.replaceAll('\n', '').replaceAll('"', '').split(',');

          return {
            parentSkuCode: values[0],
            postType: values[1].toUpperCase(),
            authorKocUserRole: values[2].toUpperCase(),
            authorRebateRate: Number(values[3]),
            buyerKocUserRole: values[4].toUpperCase(),
            buyerRebateRate: Number(values[5]),
          } as RebateRuleOverride;
        });
      setRebateRules(result);
    };
    reader.readAsText(csv);

    return false;
  }, []);

  const isValidDateRange = useMemo(() => {
    const start = moment(startDate);
    const end = moment(endDate);

    if (!start.isValid() || !end.isValid()) {
      return true;
    }

    return start.isBefore(end);
  }, [endDate, startDate]);

  const footer = useMemo(
    () => (
      <div className="flex justify-end gap-1">
        <DownloadLink />
        <Upload
          beforeUpload={onUploadClick}
          onChange={({ file }) => setUploading(file.status === 'uploading')}
          accept=".csv"
          showUploadList={false}
          maxCount={1}
        >
          <Button>Import</Button>
        </Upload>
        <Button
          onClick={() => {
            onSubmit({
              name,
              startDate,
              endDate,
              rules: rebateRules.map((rule) => ({
                ...rule,
                disabled: false,
              })),
            });
            reset();
          }}
          disabled={name.length === 0 || !isValidDateRange || rebateRules.length === 0}
        >
          Confirm
        </Button>
      </div>
    ),
    [endDate, isValidDateRange, name, onSubmit, onUploadClick, rebateRules, startDate],
  );

  const reset = () => {
    setName('');
    setStartDate('');
    setEndDate('');
    setRebateRules([]);
  };

  const columns: ColumnsType<RebateRuleOverride> = [
    {
      dataIndex: 'parentSkuCode',
      title: 'Row',
      align: 'center' as const,
      render: (_value, _record, index: number) => <span>{index + 1}</span>,
    },
    {
      dataIndex: 'parentSkuCode',
      title: 'Parent Sku Code',
      align: 'center' as const,
    },
    {
      dataIndex: 'postType',
      title: 'Post Type',
      align: 'center' as const,
    },
    {
      dataIndex: 'authorKocUserRole',
      title: 'Author KOC User Role',
      align: 'center' as const,
    },
    {
      dataIndex: 'authorRebateRate',
      title: 'Author Rebate Rate',
      align: 'center' as const,
      render: (value: number) => <span>{value.toFixed(2)}</span>,
    },
    {
      dataIndex: 'buyerKocUserRole',
      title: 'Buyer KOC User Role',
      align: 'center' as const,
    },
    {
      dataIndex: 'buyerRebateRate',
      title: 'Buyer Rebate Rate',
      align: 'center' as const,
      render: (value: number) => <span>{value.toFixed(2)}</span>,
    },
  ];

  return (
    <Modal
      {...modalProps}
      width="75%"
      footer={footer}
      destroyOnClose
      onCancel={() => {
        setModalOpen(false);
        reset();
      }}
    >
      <Form labelCol={{ span: 2 }} labelAlign="left" layout="horizontal" preserve={false}>
        <Form.Item label="Name" wrapperCol={{ span: 5 }} rules={[{ required: true }]}>
          <Input onChange={(e) => setName(e.target.value)} />
        </Form.Item>
        <Form.Item label="Start Date">
          <DateTimePicker
            minDate={moment()}
            onChange={(_, dateString) => {
              setStartDate(moment(dateString).format('YYYY-MM-DDTHH:mm:ssZ'));
            }}
            valid={isValidDateRange}
          />
        </Form.Item>
        <Form.Item label="End Date">
          <DateTimePicker
            minDate={moment()}
            onChange={(_, dateString) =>
              setEndDate(moment(dateString).format('YYYY-MM-DDTHH:mm:ssZ'))
            }
            valid={isValidDateRange}
          />
        </Form.Item>
      </Form>
      <Table
        columns={columns}
        dataSource={rebateRules}
        pagination={false}
        loading={uploading}
        bordered
      />
    </Modal>
  );
};

export default CreateRebateOverrideModal;
