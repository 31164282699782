import { AffiliateBudgetParam, AffiliateBudgetPostData } from '@/Interfaces/I_Affiliate';
import { RebateRuleOverride } from '@/Interfaces/I_RebateOverride';
import { customAxios } from '../index';

const getAffiliateBudgetDataAPI = (data: AffiliateBudgetParam) =>
  customAxios({
    url: 'hktv_koc/cms/budget_policies/list',
    method: 'GET',
    params: data,
  });

const postAffiliateBudgetAPI = (data: AffiliateBudgetPostData) =>
  customAxios.post('/hktv_koc/cms/budget_policies/create', data);

const patchAffiliateBudgetRecalculateAPI = (id: number) =>
  customAxios({
    url: `hktv_koc/cms/budget_policies/${id}/spent`,
    method: 'PATCH',
  });

const getAllAffiliateRebateRuleProductOverrides = () =>
  customAxios.get('/hktv_koc/cms/rebate/product_overrides');

const deleteAffiliateRebateRuleProductOverride = (rule: RebateRuleOverride) =>
  customAxios({
    url: 'hktv_koc/cms/rebate',
    method: 'DELETE',
    data: rule,
  });

export {
  getAffiliateBudgetDataAPI,
  postAffiliateBudgetAPI,
  patchAffiliateBudgetRecalculateAPI,
  getAllAffiliateRebateRuleProductOverrides,
  deleteAffiliateRebateRuleProductOverride,
};
