import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import rootSaga from '@/Redux/rootSaga';
import accountsManagementSlice from '@/Redux/slices/AccountsManagementSlice';
import postManagementSlice from '@/Redux/slices/PostManagementSlice';
import reportSlice from '@/Redux/slices/ReportSlice';
import accountReducer from '@/Slices/AccountSlice';
import censoredSlice from '@/Slices/CensorshipSlice';
import whitelistDomainSlice from '@/Slices/WhitelistDomainSlice';
// Slices
import ModalSlice from '@/Slices/ModalSlice';
import settingSlice from '@/Slices/SettingSlice';
import userRoleSlice from '@/Slices/UserRoleSlice';
import CampaignSlice from '@/Slices/CampaignSlice';
import liveShowSlice from './slices/LiveShowSlice';
import affiliateSlice from './slices/AffiliateSlice';
import vodSlice from './slices/VodSlice';
import RebateTncSlice from './slices/RebateTncSlice';
import PostCollectionSlice from './slices/PostCollectionSlice';
import RebateOverrideSlice from './slices/RebateOverrideSlice';

const sagaMiddleware = createSagaMiddleware();

const middleware = [
  ...getDefaultMiddleware({
    thunk: false,

    serializableCheck: false,
  }),
  sagaMiddleware,
];

const reducer = combineReducers({
  account: accountReducer,
  accountsManagement: accountsManagementSlice,
  affiliate: affiliateSlice,
  campaign: CampaignSlice,
  liveShow: liveShowSlice,
  vod: vodSlice,
  postManagement: postManagementSlice,
  modal: ModalSlice,
  censorship: censoredSlice,
  report: reportSlice,
  rebateTnc: RebateTncSlice,
  setting: settingSlice,
  userRole: userRoleSlice,
  whitelistDomain: whitelistDomainSlice,
  postCollection: PostCollectionSlice,
  rebateOverride: RebateOverrideSlice,
});

const store = configureStore({
  reducer,
  middleware,
});

sagaMiddleware.run(rootSaga);

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
