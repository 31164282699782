import React, { useState } from 'react';

import Tab from '@/Modules/tab/Tab';

import KocBudgetPool from './KocBudgetPool';
import VodBudgetPool from './VodBudgetPool';
import RebateOverrideCSV from './RebateOverrideCSV';
import RebateRuleProuductOverride from './RebateRuleProductOverride';

const Index = () => {
  const [currentKey, setCurrentKey] = useState('1');

  return (
    <Tab
      defaultActiveKey={currentKey}
      initialPanes={[
        { title: 'KOC', content: <KocBudgetPool />, key: '1' },
        { title: 'VOD Buy', content: <VodBudgetPool />, key: '2' },
        {
          title: 'Affiliate Rebate Rule Product Override',
          content: <RebateRuleProuductOverride active={currentKey === '3'} />,
          key: 3,
        },
        {
          title: 'Rebate Override CSV',
          content: <RebateOverrideCSV active={currentKey === '4'} />,
          key: '4',
        },
      ]}
      onChange={setCurrentKey}
    />
  );
};

export default Index;
