import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';
import { RebateRuleOverride } from '@/Interfaces/I_RebateOverride';
import {
  getAllRebateRuleProductOverrides,
  deleteRebateRuleProductOverride,
} from '@/Redux/slices/AffiliateSlice';
import { DeleteOutlined } from '@ant-design/icons';
import { Empty, Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect } from 'react';

const RebateRuleProuductOverride = ({ active }: { active: boolean }) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.affiliate);
  const { data } = useAppSelector((state) => state.affiliate.value.rebateRuleProductOverride);

  useEffect(() => {
    if (active) {
      dispatch(getAllRebateRuleProductOverrides());
    }
  }, [active, dispatch]);

  const columns: ColumnsType<RebateRuleOverride> = [
    {
      dataIndex: 'parentSkuCode',
      title: 'Parent Sku Code',
      align: 'center' as const,
    },
    {
      dataIndex: 'postType',
      title: 'Post Type',
      align: 'center' as const,
    },
    {
      dataIndex: 'authorKocUserRole',
      title: 'Author KOC User Role',
      align: 'center' as const,
    },
    {
      dataIndex: 'authorRebateRate',
      title: 'Author Rebate Rate',
      align: 'center' as const,
      render: (value: number) => <span>{value.toFixed(2)}</span>,
    },
    {
      dataIndex: 'buyerKocUserRole',
      title: 'Buyer KOC User Role',
      align: 'center' as const,
    },
    {
      dataIndex: 'buyerRebateRate',
      title: 'Buyer Rebate Rate',
      align: 'center' as const,
      render: (value: number) => <span>{value.toFixed(2)}</span>,
    },
    {
      dataIndex: 'startDate',
      title: 'Start Date',
      align: 'center' as const,
      render: (value: string) => <span>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      dataIndex: 'endDate',
      title: 'End Date',
      align: 'center' as const,
      render: (value: string) => <span>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      dataIndex: 'id',
      title: 'Delete',
      align: 'center' as const,
      render: (_, rule) => (
        <Popconfirm
          title="Confirm to delete?"
          onConfirm={() => dispatch(deleteRebateRuleProductOverride(rule))}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className="h-auto bg-white p-6 flex flex-col gap-6">
      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        pagination={{ total: data.length }}
      />
      {!loading && data.length === 0 && <Empty />}
    </div>
  );
};

export default RebateRuleProuductOverride;
