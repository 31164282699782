import CreateRebateOverrideModal from '@/Components/CreateRebateOverrideModal';
import DetailRebateOverrideModal from '@/Components/DetailRebateOverrideModal';
import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';
import { RebateOverride as RebateOverrideType } from '@/Interfaces/I_RebateOverride';
import { createRebateOverride, getRebateOverrides } from '@/Redux/slices/RebateOverrideSlice';
import { FileTextOutlined } from '@ant-design/icons';
import { Button, Empty, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const RebateOverrideCSV = ({ active }: { active: boolean }) => {
  const dispatch = useAppDispatch();
  const { rebateOverrides, loading } = useAppSelector((state) => state.rebateOverride);

  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [modalData, setModalData] = useState<RebateOverrideType>({} as RebateOverrideType);

  useEffect(() => {
    if (active) {
      dispatch(getRebateOverrides({ page: 1 }));
    }
  }, [dispatch, active]);

  const onEditClick = (rebateOverride: RebateOverrideType) => {
    setUpdateModalOpen(true);
    setModalData(rebateOverride);
  };

  const columns: ColumnsType<RebateOverrideType> = [
    {
      dataIndex: 'id',
      title: 'Id',
      align: 'center' as const,
    },
    {
      dataIndex: 'name',
      title: 'Rebate Override Name',
      align: 'center' as const,
      render: (name: string, rebateOverride: RebateOverrideType) => (
        <div>
          <span>{name}</span>
          <FileTextOutlined
            className="cursor-pointer ml-2"
            onClick={() => onEditClick(rebateOverride)}
          />
        </div>
      ),
    },
    {
      dataIndex: 'startDate',
      title: 'Start Date',
      align: 'center' as const,
      render: (date: string) => <span>{moment(date).format('YYYY-MM-DD HH:mm')}</span>,
    },
    {
      dataIndex: 'endDate',
      title: 'End Date',
      align: 'center' as const,
      render: (date: string) => <span>{moment(date).format('YYYY-MM-DD HH:mm')}</span>,
    },
    {
      dataIndex: 'creationDate',
      title: 'Creation Date',
      align: 'center' as const,
      render: (date: string) => <span>{moment(date).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
  ];

  return (
    <>
      <div className="h-auto bg-white p-6 flex flex-col gap-6">
        <Button className="place-self-end" onClick={() => setCreateModalOpen(true)}>
          Create
        </Button>
        <Table
          columns={columns}
          dataSource={rebateOverrides.data}
          loading={loading}
          pagination={{
            total: rebateOverrides.totalPages * 10,
            current: rebateOverrides.currentPage,
            pageSize: 10,
            onChange: (page) => dispatch(getRebateOverrides({ page })),
          }}
        />
        {!loading && rebateOverrides.data.length === 0 && <Empty />}
      </div>
      <CreateRebateOverrideModal
        open={isCreateModalOpen}
        onSubmit={(rebateOverride) => {
          dispatch(createRebateOverride(rebateOverride));
          setCreateModalOpen(false);
        }}
        setModalOpen={setCreateModalOpen}
      />
      <DetailRebateOverrideModal
        loading={loading}
        open={isUpdateModalOpen}
        data={modalData}
        onCancel={() => setUpdateModalOpen(false)}
      />
    </>
  );
};

export default RebateOverrideCSV;
