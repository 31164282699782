import React, { useEffect, useRef, useState } from 'react';

import { Button, Empty, Select, Table } from 'antd';

import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';
import { UserRoleEnums } from '@/Interfaces/I_userRole';

import { getUserRole, putUserRole } from '@/Redux/slices/UserRoleSlice';

import UserRoleAccountModal from '@/Components/UserRoleAccountModal';

import UserRoleRadioOptions from './UserRoleRadioOptions';

const UserRole = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isAddNewRoleVisible, setIsAddNewRoleVisible] = useState(false);
  const selectRole = useRef<string>('');
  const userRoleLoading = useAppSelector((state) => state.userRole.loading);
  const userRoleData = useAppSelector((state) => state.userRole.value?.userRoles?.data);
  const pagination = useAppSelector((state) => state.userRole.value.userRoles.pagination);

  const roleOptions = [
    { value: null, label: '全部' },
    { value: 'OFFICIAL', label: '官方賬戶' },
    { value: 'CONTENT_PROVIDER', label: '話題領袖' },
    { value: 'MERCHANT', label: 'HKTVmall商戶' },
    { value: 'HKTV_SHOPS_MERCHANT', label: 'HKTVshops商戶' },
    { value: 'YOUTUBER', label: 'LOOK創作者' },
  ];

  useEffect(() => {
    dispatch(getUserRole({}));
  }, [dispatch]);

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: 'User Name',
      dataIndex: 'userName',
      key: 'userName',
      render: (text: string) => <p className="h-6 line-clamp-1 break-all m-0">{text}</p>,
      width: '30%',
    },
    {
      title: 'User PKey',
      dataIndex: 'hybrisId',
      key: 'hybrisId',
    },
    {
      title: 'Role',
      dataIndex: 'userRole',
      key: 'userRole',
      width: '40%',
      render: (userRole: { role: UserRoleEnums; userPk: string }) => {
        const { role, userPk } = userRole;
        return <UserRoleRadioOptions userPk={userPk} userRole={role} />;
      },
    },
    {
      title: '',
      dataIndex: 'resetButton',
      key: 'resetButton',
      render: (
        resetParam: { userPk: string, resetOnClick: (userPk: string) => void }
      ) => {
        const { userPk, resetOnClick } = resetParam;
        return (
          userPk && <Button danger onClick={() => resetOnClick(userPk)}>RESET</Button>
        )
        
      },
      width: '10%',
    }
  ];

  const resetOnClick = (userPk: string) => {
    dispatch(putUserRole([{hybrisId: userPk, kocUserRole: "CUSTOMER"}]))
  }

  return (
    <div className="h-auto bg-white p-6 pb-2">
      <div className="flex justify-between">
        <Select
          defaultValue={roleOptions[0].value}
          style={{ width: 300 }}
          options={roleOptions}
          onChange={(value) => {
            selectRole.current = value;
            dispatch(getUserRole({ role: selectRole.current }));
          }}
        />
        <Button
          type="primary"
          onClick={() => {
            setIsAddNewRoleVisible(true);
          }}
        >
          New Role
        </Button>
      </div>
      {!userRoleLoading && userRoleData?.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
      ) : (
        <Table
          className="mt-4"
          columns={columns}
          loading={userRoleLoading}
          dataSource={userRoleData?.map((data, index: number) => ({
            key: data.hybrisId,
            no: pagination.page * pagination.pageSize + index + 1,
            userName: data.name,
            hybrisId: data.hybrisId,
            userRole: {
              role: data.kocUserRole,
              userPk: data.hybrisId,
            },
            resetButton: {
              userPk: data.hybrisId,
              resetOnClick,
            },
          }))
        }
          pagination={{
            total: pagination ? pagination.total : 0,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            defaultPageSize: pageSize,
            defaultCurrent: 1,
            showSizeChanger: true,
            current: currentPage + 1,
            onChange: (page: number, selectPageSize: number) => {
              setCurrentPage(page - 1);
              dispatch(
                getUserRole({
                  role: selectRole.current,
                  page_no: page - 1,
                  page_size: selectPageSize,
                }),
              );
            },
            onShowSizeChange: (current, size) => {
              setPageSize(size);
            },
          }}
        />
      )}
      <UserRoleAccountModal
        isAddNewRoleVisible={isAddNewRoleVisible}
        setIsAddNewRoleVisible={setIsAddNewRoleVisible}
      />
    </div>
  );
};

export default UserRole;
