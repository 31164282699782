import { RebateOverride } from '@/Interfaces/I_RebateOverride';
import { customAxios } from '..';

export const getAllRebateOverrides = (page = 1) =>
  customAxios({
    url: `hktv_koc/cms/rebate?pageNo=${page}`,
    method: 'GET',
  });

export const createRebateOverride = (rebateOverride: RebateOverride) =>
  customAxios({
    url: `hktv_koc/cms/rebate`,
    method: 'POST',
    data: rebateOverride,
  });
