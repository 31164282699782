/* eslint-disable react/jsx-props-no-spreading */
import { RebateOverride, RebateRuleOverride } from '@/Interfaces/I_RebateOverride';
import { Button, DatePicker, Form, Input, Modal, ModalProps, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';

type RebateModalProps = {
  data: RebateOverride;
  loading: boolean;
} & Omit<ModalProps, 'onOk'>;

const csvHeaders = [
  'parent_sku_code',
  'post_type',
  'author_koc_user_role',
  'author_rebate_rate',
  'buyer_koc_user_role',
  'buyer_rebate_rate',
  'disabled',
];

const DownloadLink = ({ rebateOverride }: { rebateOverride?: RebateOverride }) => {
  const data = useMemo(
    () =>
      (rebateOverride?.rules || []).map((rule) => [
        rule.parentSkuCode,
        rule.postType,
        rule.authorKocUserRole,
        rule.authorRebateRate,
        rule.buyerKocUserRole,
        rule.buyerRebateRate,
        rule.disabled,
      ]),
    [rebateOverride],
  );

  return (
    <CSVLink
      style={{ color: 'rgba(0, 0, 0, 0.85)' }}
      data={data}
      target="_blank"
      filename={`${rebateOverride?.name}.csv`}
      uFEFF
      headers={csvHeaders}
    >
      <Button>Export</Button>
    </CSVLink>
  );
};

const DetailRebateOverrideModal = ({ data, loading, ...modalProps }: RebateModalProps) => {
  const [rebateRules, setRebateRules] = useState<RebateRuleOverride[]>(data?.rules || []);

  useEffect(() => {
    setRebateRules(data.rules);
  }, [data]);

  const footer = useMemo(
    () => (
      <div className="flex justify-end gap-1">
        <DownloadLink rebateOverride={data} />
      </div>
    ),
    [data],
  );

  const columns: ColumnsType<RebateRuleOverride> = [
    {
      dataIndex: 'parentSkuCode',
      title: 'Row',
      align: 'center' as const,
      render: (_value, _record, index: number) => <span>{index + 1}</span>,
    },
    {
      dataIndex: 'parentSkuCode',
      title: 'Parent Sku Code',
      align: 'center' as const,
    },
    {
      dataIndex: 'postType',
      title: 'Post Type',
      align: 'center' as const,
    },
    {
      dataIndex: 'authorKocUserRole',
      title: 'Author KOC User Role',
      align: 'center' as const,
    },
    {
      dataIndex: 'authorRebateRate',
      title: 'Author Rebate Rate',
      align: 'center' as const,
      render: (value: number) => <span>{value.toFixed(2)}</span>,
    },
    {
      dataIndex: 'buyerKocUserRole',
      title: 'Buyer KOC User Role',
      align: 'center' as const,
    },
    {
      dataIndex: 'buyerRebateRate',
      title: 'Buyer Rebate Rate',
      align: 'center' as const,
      render: (value: number) => <span>{value.toFixed(2)}</span>,
    },
    {
      dataIndex: 'disabled',
      title: 'Disabled',
      align: 'center' as const,
      render: (value: boolean, _) => <Switch defaultChecked={value} disabled />,
    },
  ];

  return (
    <Modal {...modalProps} width="75%" footer={footer} destroyOnClose>
      <Form labelCol={{ span: 2 }} labelAlign="left" layout="horizontal" preserve={false}>
        <Form.Item label="Name" wrapperCol={{ span: 5 }}>
          <Input defaultValue={data?.name} disabled />
        </Form.Item>
        <Form.Item label="Start Date">
          <DatePicker disabled defaultValue={moment(data.startDate)} format="YYYY-MM-DD HH:mm:ss" />
        </Form.Item>
        <Form.Item label="End Date">
          <DatePicker disabled defaultValue={moment(data.endDate)} format="YYYY-MM-DD HH:mm:ss" />
        </Form.Item>
      </Form>
      <Table
        columns={columns}
        dataSource={rebateRules}
        pagination={false}
        bordered
        loading={loading}
      />
    </Modal>
  );
};

export default DetailRebateOverrideModal;
