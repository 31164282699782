import { createSlice } from '@reduxjs/toolkit';

import { UserRolePagedData } from '@/Interfaces/I_userRole';

const userRoleSlice = createSlice({
  name: 'userRoleSlice',
  initialState: {
    value: {
      userRoles: {} as UserRolePagedData,
    },
    loading: false,
    error: '',
  },
  reducers: {
    getUserRole: (state, action) => {
      const assignState = state;
      assignState.loading = true;
    },
    setUserRole: (state, action) => {
      const assignState = state;
      assignState.value = { ...assignState.value, userRoles: action.payload.data };
      assignState.loading = false;
    },
    putUserRole: (state, action) => {},
    spliceUserRole: (state, action) => {
      const assignState = state;
      const tempUserRole = assignState.value.userRoles;
      action.payload.forEach((spliceUser: {hybrisId: string}) => {
        const targetUserIndex = tempUserRole.data.findIndex((user) => user.hybrisId === spliceUser.hybrisId);
        tempUserRole.data.splice(targetUserIndex, 1)
      })
      assignState.value = { ...assignState.value, userRoles: tempUserRole};
    }
  },
});

export const { getUserRole, setUserRole, putUserRole, spliceUserRole } = userRoleSlice.actions;

export default userRoleSlice.reducer;
